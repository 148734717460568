import React from 'react'
import person001 from '../assets/img/person-001.png'
import person002 from '../assets/img/person-002.png'
import person003 from '../assets/img/person-003.png'
import Review from './Review'
import Title from './Title'

function Reviews() {
  return (
    <section id="reviews_block">
      <Title
        type="section-title__default"
        text="What People Say About the App"
      />
      <div className="container">
        <div className="reviews">
          <Review
            image={person001}
            align="review__right"
            name="Emily Johnson"
            position="Software Engineer"
            text="As a software engineer, I'm impressed by Threat-alert's innovative approach. The data encryption and user anonymity features are top-notch. This app is a game-changer for those concerned about data security."
          />
          <Review
            image={person002}
            align="review__left"
            name="David Martinez"
            position="Cybersecurity Analyst"
            text="Being a cybersecurity analyst, I scrutinize apps for vulnerabilities. Threat-alert exceeds expectations. Its decentralized data storage and adherence to GDPR standards make it a solid choice for privacy-conscious users."
          />
          <Review
            image={person003}
            align="review__right"
            name="Jessica Wong"
            position="Journalist"
            text="As a journalist, I handle sensitive information daily. Threat-alert's end-to-end encryption and regular security audits put my mind at ease. It's a user-friendly solution for anyone valuing confidentiality."
          />
        </div>
      </div>
    </section>
  )
}

export default Reviews
