import React from 'react'
import Slider from 'react-slick'
import Title from './Title'
import Slide from './Slide'
import pic1 from '../assets/img/Screen_001.png'
import pic2 from '../assets/img/Screen_002.png'
import pic3 from '../assets/img/Screen_003.png'
import pic4 from '../assets/img/Screen_004.png'
import pic5 from '../assets/img/Screen_005.png'
import pic6 from '../assets/img/Screen_006.png'
import pic7 from '../assets/img/Screen_007.png'

function Product() {

  const slides = [
    { id: 1, pic: pic1, title: 'Screen 1' },
    { id: 2, pic: pic2, title: 'Screen 2' },
    { id: 3, pic: pic3, title: 'Screen 3' },
    { id: 4, pic: pic4, title: 'Screen 4' },
    { id: 5, pic: pic5, title: 'Screen 5' },
    { id: 6, pic: pic6, title: 'Screen 6' },
    { id: 7, pic: pic7, title: 'Screen 7' }
  ]

  const settings = {
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <section id="product_block">
      <br /><br /><br />
      <Title
        type="section-title__default"
        text="How It Looks"
      />
      <div className="container">
        <Slider {...settings}>
          {slides.map(screen => (
            <Slide pic={screen.pic} order={screen.id} title={screen.title} />
          ))}
        </Slider>
      </div>
      <br /><br /><br />
    </section>
  );
}

export default Product
