import React from 'react'
import google from '../assets/img/google-play.png'
import apple from '../assets/img/app-store.png'
import shareFacebook from '../assets/img/icons/share/facebook.svg'
import shareInstagram from '../assets/img/icons/share/instagram.svg'
import shareLinkedin from '../assets/img/icons/share/linkedin.svg'
import shareMedium from '../assets/img/icons/share/medium.svg'
import shareTwitter from '../assets/img/icons/share/twitter.svg'
import Title from './Title'

function Download() {
  const site = 'https://threat-alert.online/'

  const share = (url) => {
    window.open(url, '_blank')
  }

  return (
    <section id="download_block">
      <br /><br /><br />
      <Title type="section-title__white" text="Download Threat-alert App" />
      <div className="container text-center">
        <div className="download">
          <a className="download__link" href="#download_block">
            <img src={apple} className="download__img" alt="Download on the App Store" />
          </a>
          <a className="download__link" href="#download_block">
            <img src={google} className="download__img" alt="Get it on Google Play" />
          </a>
        </div>
        <h4 className="share__title">Share App with your Friends & Teams</h4>
        <div className="share">
          <span className="share__link" onClick={() => share(`https://www.facebook.com/sharer/sharer.php?u=${site}`)}>
            <img src={shareFacebook} className="share__image" alt="Facebook" />
          </span>
          <span className="share__link" onClick={() => share(`https://medium.com/p/${site}/share`)}>
            <img src={shareMedium} className="share__image" alt="Medium" />
          </span>
          <span className="share__link" onClick={() => share(`https://www.instagram.com/?url=${site}`)}>
            <img src={shareInstagram} className="share__image" alt="Instagram" />
          </span>
          <span className="share__link" onClick={() => share(`https://twitter.com/intent/tweet?url=${site}`)}>
            <img src={shareTwitter} className="share__image" alt="Twitter" />
          </span>
          <span className="share__link" onClick={() => share(`https://www.linkedin.com/shareArticle?mini=true&url=${site}`)}>
            <img src={shareLinkedin} className="share__image" alt="Linkedin" />
          </span>
        </div>
      </div>
    </section>
  )
}

export default Download
