import React from 'react'
import MenuPages from './MenuPages'

function Header() {
  return (
    <section id="top_block">
      <div className="container">
        <MenuPages />
      </div>
    </section>
  );
}

export default Header
