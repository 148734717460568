import * as $ from 'jquery'

$(document).ready(function() {
  $('.hb-burger').on('click', function() {
    $(this).toggleClass('opened');
    $('.hb-menu').toggleClass('mob-menu');
    $('body').toggleClass('fix-mob');
  });
  $('.mob-link').on('click', function() {
    $('.hb-menu').toggleClass('mob-menu');
    $('.hb-burger').toggleClass('opened');
  });
  // Scroll To
  $(".scroll").click(function(event){
    event.preventDefault();
    var full_url = this.href;
    var parts = full_url.split("#");
    var trgt = parts[1];
    var target_offset = $("#"+trgt).offset();
    var target_top = target_offset.top - 78;
    $('html, body').animate({scrollTop:target_top}, 1500);
  });
});
