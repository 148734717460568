import React from 'react'
import Title from './Title'
import i01 from '../assets/img/icons/about/i01.svg'
import i02 from '../assets/img/icons/about/i02.svg'
import i03 from '../assets/img/icons/about/i03.svg'
import i04 from '../assets/img/icons/about/i04.svg'

function About() {
  return (
    <section id="about_block">
      <Title
        type="section-title__default"
        text="How It Works"
      />
      <div className="container tc">
        <div className="w-80 ma">
          <h2 className='w-60 ma about-sub'>Mobile app for analyzing potential nuclear strikes based on global internet routing changes and region-wide connectivity loss</h2>
          <div className="s-60"></div>
          <div className="flex jcsb w-100 about-mob-block">
            <div className="my-3 w-50 about-mob-100">
              <p className='about__num'>
                <img src={i01} alt="Creating Peer-to-Peer Network" />
              </p>
              <p className='about__dots about__first'>Creating Peer-to-Peer Network</p>
              <p className='about__dots'>Users form a decentralized network to share data.</p>
            </div>
            <div className="my-3 w-50 about-mob-100">
              <p className='about__num'>
                <img src={i02} alt="Data and Metric Exchange" />
              </p>
              <p className='about__dots about__first'>Data and Metric Exchange</p>
              <p className='about__dots'>Peer nodes exchange metrics and data.</p>
            </div>
          </div>
          <div className="flex jcsb w-100 about-mob-block">
            <div className="my-3 w-50 about-mob-100">
              <p className='about__num'>
                <img src={i03} alt="Data Analysis on Server" />
              </p>
              <p className='about__dots about__first'>Data Analysis on Server</p>
              <p className='about__dots'>App server analyzes collected data.</p>
            </div>
            <div className="my-3 w-50 about-mob-100">
              <p className='about__num'>
                <img src={i04} alt="Anomaly Notifications" />
              </p>
              <p className='about__dots about__first'>Anomaly Notifications</p>
              <p className='about__dots w-70 ma'>Users receive alerts if anomalies indicating potential threats are detected.</p>
            </div>
          </div>
        </div>
      </div>
      <br /><br /><br /><br />
    </section>
  )
}

export default About
