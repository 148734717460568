import React from 'react'
import Header from '../components/Header'
import Intro from '../components/Intro'
import About from '../components/About'
import Security from '../components/Security'
import Product from '../components/Product'
import Reviews from '../components/Reviews'
import Download from '../components/Download'
import Contact from '../components/Contact'

function Home() {
  return (
    <>
      <Header />
      <Intro />
      <About />
      <Security />
      <Product />
      <Reviews />
      <Contact />
      <Download />
    </>
  )
}

export default Home
