import React from 'react'

function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer">
      <div className="container text-center">
        <div className="menu--footer">
          <br /><br />
          <a className="menu--footer__link active" href="/">Home</a>
          <a className="menu--footer__link" href="/privacy">Privacy Policy</a>
          <a className="menu--footer__link" href="/terms">Terms of Use</a>
          <a className="menu--footer__link" href="/cookie">Cookie Policy</a>
        </div>
        <p className="menu--footer__text">Threat-alert - &copy;{currentYear}. All Rights Reserved</p>
      </div>
    </footer>
  );
}

export default Footer
