import React from 'react'
import { Link } from 'react-router-dom'
import HeaderPages from '../components/HeaderPages'

function Privacy() {
  return (
    <>
      <HeaderPages />
      <section className="page">
        <div className="page__header">
          <div className="container">
            <div className="page__navigation">
              <h3 className="page__title">Privacy Policy</h3>
              <div className="page__pagination">
                <Link to="/terms" className="page__link page__link--active">Terms of Use</Link>
                <p className="page__link">::</p>
                <Link to="/cookie" className="page__link page__link--active">Cookie Policy</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="page__content">
            <h1>Privacy Policy for Threat-alert</h1>

            <p><strong>Effective date:</strong> 17 February 2024</p>

            <p>Welcome to Threat-alert ("we," "us," "our"). We are committed to protecting your privacy and ensuring the security of your information. This Privacy Policy explains how we collect, use, share, and protect information in relation to our mobile application, Threat-alert (the "App"), and your choices about the collection and use of your information.</p>

            <h2>1. Information We Collect</h2>

            <p>We collect the following types of information:</p>

            <ul>
              <li><strong>Metrics and Data Exchange:</strong> Our App collects aggregated metrics related to global internet routing changes and region-wide connectivity loss. This data is used to analyze potential nuclear strikes and other global threats.</li>
              <li><strong>Anonymous User IDs:</strong> To enhance privacy, we assign unique IDs to our users. These IDs are used to manage the data exchange within our decentralized peer-to-peer network.</li>
            </ul>

            <h2>2. How We Use Your Information</h2>

            <p>We use the information we collect to:</p>

            <ul>
              <li>Operate, maintain, and improve our App.</li>
              <li>Analyze and monitor data for the detection of potential global threats.</li>
              <li>Enhance the security and privacy features of the App.</li>
            </ul>

            <h2>3. Data Sharing and Disclosure</h2>

            <p>We do not share or disclose any personal information with third parties except in the following limited circumstances:</p>

            <ul>
              <li>To comply with legal obligations or to protect the rights, property, or safety of our users.</li>
              <li>With your consent or at your direction.</li>
            </ul>

            <h2>4. Data Storage and Security</h2>

            <ul>
              <li><strong>Decentralized Storage:</strong> Your data is stored across a decentralized network, reducing the risk of data breaches.</li>
              <li><strong>End-to-End Encryption:</strong> All data exchanged within the network is encrypted, ensuring that only authorized users can access it.</li>
              <li><strong>Security Measures:</strong> We implement robust security measures including compliance with ISO 27001 standards and regular security audits.</li>
            </ul>

            <h2>5. Legal Compliance</h2>

            <p>We are committed to complying with the General Data Protection Regulation (GDPR) and other relevant data protection laws to protect user privacy and ensure data integrity.</p>

            <h2>6. Your Data Rights</h2>

            <p>You have the right to access, correct, or delete your data at any time. Please contact us if you wish to exercise these rights.</p>

            <h2>7. Changes to This Privacy Policy</h2>

            <p>We may modify this Privacy Policy from time to time. The most current version will always be posted on our website. We encourage you to review it regularly.</p>

            <h2>8. Contact Us</h2>

            <p>If you have any questions about this Privacy Policy or the App, please contact us through the channels provided on our website.</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Privacy
