import React from 'react'
import { Link } from 'react-router-dom'
import HeaderPages from '../components/HeaderPages'

function Terms() {
  return (
    <>
      <HeaderPages />
      <section className="page">
        <div className="page__header">
          <div className="container">
            <div className="page__navigation">
              <h3 className="page__title">Terms of Use</h3>
              <div className="page__pagination">
                <Link to="/privacy" className="page__link page__link--active">Privacy Policy</Link>
                <p className="page__link">::</p>
                <Link to="/cookie" className="page__link page__link--active">Cookie Policy</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="page__content">
            <h1>Terms of Use for Threat-alert</h1>

            <p><strong>Effective date:</strong> 17 February 2024</p>

            <p>Welcome to Threat-alert. These Terms of Use ("Terms") govern your use of the Threat-alert mobile application (the "App"), and any services provided through the App. By accessing or using the App, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use the App.</p>

            <h2>1. Use of the App</h2>

            <p>The App is intended for personal, non-commercial use. You agree not to use the App for any unlawful purpose, in a way that could damage, disable, overburden, or impair the App or interfere with any other party's use of the App.</p>

            <h2>2. Intellectual Property</h2>

            <p>All content on the App, including but not limited to text, graphics, logos, and software, is the property of Threat-alert or its content suppliers and is protected by copyright and other intellectual property laws. You may not use any content from the App without the express permission of Threat-alert.</p>

            <h2>3. User Content</h2>

            <p>You may have the opportunity to create or upload content, such as comments or reviews. You retain all rights to any content you submit, post, or display on or through the App and you are responsible for protecting those rights.</p>

            <h2>4. Privacy</h2>

            <p>Your use of the App is also governed by our Privacy Policy, which outlines how we collect, use, and protect your data. Please refer to our Privacy Policy for more information.</p>

            <h2>5. Changes to Terms of Use</h2>

            <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on the App. Your continued use of the App after any such changes constitutes your acceptance of the new Terms.</p>

            <h2>6. Disclaimer of Warranties</h2>

            <p>The App is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not guarantee the accuracy, completeness, or usefulness of the App, and you rely on the App at your own risk.</p>

            <h2>7. Limitation of Liability</h2>

            <p>In no event will Threat-alert, its affiliates, agents, directors, employees, suppliers, or licensors be liable for any indirect, punitive, incidental, special, consequential, or exemplary damages arising out of or in connection with your use of the App.</p>

            <h2>8. Governing Law</h2>

            <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which the company is registered, without giving effect to any principles of conflicts of law.</p>

            <h2>9. Contact Us</h2>

            <p>If you have any questions about these Terms, please contact us through the channels provided on our website.</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Terms
