import React from "react"
import Title from "./Title"
import Form from "./Form"

function Contact() {

  return (
    <div id="contact_block">
      <Title
        type="section-title__dark"
        text="Get In Touch"
      />
      <div className="container tс w-70">
        <h2 className='about-sub tc'>Share Your Feedback and Ideas with Us! We Value Your Input in Enhancing Our App.</h2>
        <div className="s-60"></div>
      </div>
      <Form />
      <div className="s-60"></div>
    </div>
  )
}

export default Contact
