import React from 'react'
import logo from '../assets/img/svg/LOGO.svg'


function Menu() {
  return (
    <div className="hb-header">
      <h1 className="hb-logo">
        <a className="hb-logo__link scroll" href="#site_content">
          <img src={logo} className="hb-logo__img" alt="Threat-alert" />
          <h1 className="hb-logo__text">Threat-alert</h1>
        </a>
      </h1>
      <div className="hb-menu">
        <a className="hb-menu__link scroll mob-link" href="#about_block">About</a>
        <a className="hb-menu__link scroll mob-link" href="#security_block">Security</a>
        <a className="hb-menu__link scroll mob-link" href="#product_block">Product</a>
        <a className="hb-menu__link scroll mob-link" href="#reviews_block">Reviews</a>
        <a className="hb-menu__link scroll mob-link" href="#contact_block">Contact</a>
        <a className="btn btn--premium scroll mob-link" href="#download_block">Download</a>
      </div>
    </div>
  );
}

export default Menu
