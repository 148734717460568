import React from 'react'

function Intro() {
  return (
    <div className="intro">
      <div className="container text-center">
        <h1 className="intro__title">AI and Big Data alert you to threats by monitoring internet changes</h1>
        <br /><br />
        <a className="btn btn--premium scroll" href="#download_block">Download Now</a>
      </div>
    </div>
  )
}

export default Intro
