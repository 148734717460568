import React from 'react'
import logo from '../assets/img/svg/LOGO.svg'
import { Link } from 'react-router-dom'

function MenuPages() {
  return (
    <div className="hb-header header-pages">
      <h1 className="hb-logo">
        <a className="hb-logo__link" href="/">
          <img src={logo} className="hb-logo__img" alt="Threat-alert" />
          <h1 className="hb-logo__text">Threat-alert</h1>
        </a>
      </h1>
      <div className="hb-menu">
        <Link className="hb-menu__link" to="/">Home</Link>
      </div>
    </div>
  );
}

export default MenuPages
