import React from 'react'
import Title from './Title'
import {HiOutlineShieldCheck, HiCubeTransparent, HiOutlineScale} from 'react-icons/hi2'

function Security() {

  const icon = '#790000'

  return (
    <section id="security_block">
      <Title
        type="section-title__default"
        text="Security and Privacy"
      />
      <div className="container">
        <div className="product">
          <div className="product__left">
            <HiOutlineShieldCheck color={icon} className='product__icon' />
            <h3 className='product__title'>User Data Protection Measures:<br />
              <small>We prioritize the security of user data through stringent measures:</small>
            </h3>
            <p className="product__text">
              <strong><span>🔒</span> End-to-End Encryption:</strong>
              All data exchanged within the peer-to-peer network is encrypted, ensuring that only authorized users can access the information.</p>
            <p className="product__text">
              <strong><span>🆔</span> Anonymous User IDs:</strong>
              User identities are concealed with unique IDs, enhancing privacy and preventing personal information exposure.
            </p>
            <p className="product__text">
              <strong><span>☁️</span> Decentralized Storage:</strong>
              Data isn't stored on a central server; it's distributed across the network, minimizing the risk of a single point of vulnerability.
            </p>
          </div>
          <div className="product__left">
            <HiOutlineScale color={icon} className='product__icon' />
            <h3 className="product__title">Legal Compliance and Security Standards:<br />
              <small>We're committed to adhering to legal requirements and industry security standards:</small></h3>
            <p className="product__text">
              <strong><span>🇪🇺</span> GDPR Compliance:</strong>
              Our practices align with General Data Protection Regulation guidelines, safeguarding user rights and data integrity.
            </p>
            <p className="product__text">
              <strong><span>🔒</span> ISO Security Standards:</strong>
              We follow ISO 27001 standards, implementing rigorous security controls to prevent unauthorized access.
            </p>
            <p className="product__text">
              <strong><span>🔍</span> Regular Audits:</strong>
              Our system undergoes regular security audits and assessments to ensure ongoing compliance and resilience.
            </p>
          </div>
          <div className="product__left">
            <HiCubeTransparent color={icon} className='product__icon' />
            <h3 className="product__title">Data Collection and Usage:<br />
              <small>We collect minimal data necessary for app functionality:</small></h3>
            <p className="product__text">
              <strong><span>📊</span> Metrics Exchange:</strong>
              Users share aggregated metrics related to internet connectivity changes, ensuring anonymity and privacy.
            </p>
            <p className="product__text">
              <strong><span>🔐</span> No Personal Data:</strong>
              We don't collect or store personal information such as names, addresses, or browsing history.
            </p>
            <p className="product__text">
              <strong><span>🎯</span> Data Purpose:</strong>
              Collected data is solely used for anomaly detection and global threat analysis, empowering users with crucial insights.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Security
