import React from 'react'
import { Link } from 'react-router-dom'
import HeaderPages from '../components/HeaderPages'

function Cookie() {
  return (
    <>
      <HeaderPages />
      <section className="page">
        <div className="page__header">
          <div className="container">
            <div className="page__navigation">
              <h3 className="page__title">Cookie Policy</h3>
              <div className="page__pagination">
                <Link to="/privacy" className="page__link page__link--active">Privacy Policy</Link>
                <p className="page__link">::</p>
                <Link to="/terms" className="page__link page__link--active">Terms of Use</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="page__content">
            <h1>Cookie Policy for Threat-alert</h1>

            <p>This Cookie Policy explains how Threat-alert ("we," "us," "our") uses cookies and similar technologies to recognize you when you visit our mobile application ("App"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>

            <h2>What are cookies?</h2>

            <p>Cookies are small data files that are placed on your device or computer and often include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our App.</p>

            <h2>Why do we use cookies?</h2>

            <ul>
              <li><strong>Essential Cookies:</strong> These cookies are essential to provide you with services available through our App and to use some of its features, such as access to secure areas.</li>
              <li><strong>Performance and Functionality Cookies:</strong> These cookies are used to enhance the performance and functionality of our App but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.</li>
              <li><strong>Analytics and Customization Cookies:</strong> These cookies collect information that is used either in aggregate form to help us understand how our App is being used or how effective our marketing campaigns are, or to help us customize our App for you.</li>
            </ul>

            <h2>How can I control cookies?</h2>

            <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.</p>

            <p>The specific steps to do this can vary from one browser to another and from one mobile device to another. Therefore, you may need to use your browser's help menu for instructions.</p>

            <h2>Changes to this Cookie Policy</h2>

            <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>

            <p>The date at the top of this Cookie Policy indicates when it was last updated.</p>

            <h2>Contact us</h2>

            <p>If you have any questions about our use of cookies or other technologies, please email us at the contact address provided on our website.</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cookie
